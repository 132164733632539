import React from 'react'
import AboutAwards from '../subsections/about/awards.js'
import AboutBio from '../subsections/about/bio.js'
import AboutBooks from '../subsections/about/books.js'
import AboutGuidedTrips from '../subsections/about/guided-trips.js'
import AboutTalks from '../subsections/about/talks.js'
import AboutWorkshops from '../subsections/about/workshops.js'
import Layout from '../layouts/index.js'

const About = () => (
  <Layout>
    <article>
      <h1 id="about">About</h1>
      <section id="bio">
        <AboutBio />
      </section>
      <section id="awards">
        <AboutAwards />
      </section>
      <section id="books">
        <AboutBooks />
      </section>
      <section id="talks">
        <AboutTalks />
      </section>
      <section id="guided-trips">
        <AboutGuidedTrips />
      </section>
      <section id="workshops">
        <AboutWorkshops />
      </section>
    </article>
  </Layout>
)

export default About
