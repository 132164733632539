import React from 'react'

import bio2019 from '../../images/about/bio-2019.jpg'

const AboutBio = () => (
  <section>
    <h2>Bio</h2>
    <figure>
      <img src={bio2019} alt="" />
      <figcaption>
        Sue Flood on location with Nenets reindeer herders in Siberia
      </figcaption>
    </figure>
    <article>
      <p>
        Sue Flood is an award-winning photographer and filmmaker, zoologist,
        adventure travel leader and public speaker. Her work takes her all over
        the world but she has a special passion for the wildlife and icy beauty
        of the Polar regions and is one of the very few women professional
        photographers who returns again and again to Earth’s harshest and most
        demanding environments.
      </p>
      <p>
        Her first visit to the Poles happened during her 11 years in the BBC’s
        prestigious Natural History Unit, working on such global hits such as
        The Blue Planet and Planet Earth, with Sir David Attenborough; on
        National Geographic and Discovery Channel co-productions and on the
        Disney nature movie Earth.
      </p>
      <p>
        Since then, Sue’s travels as a photographer have taken her to hundreds
        of destinations on all seven continents and found her camping at -25°C
        amid penguins, capturing rare scenes in the African bush, living aboard
        Russian ice-breakers; exploring the vast Pantanal wetlands of Brazil,
        guiding trips to exotic parts of Asia and seeking out spirit bears in
        North America.
      </p>
      <p>
        Sue was born and brought up in Wales and attended The Queens School,
        Chester before gaining a zoology degree from the University of Durham.{' '}
      </p>
      <p>
        In 2019, Sue was made an Fellow of the Royal Photographic
        Society, and an Honorary Fellow of the North American Nature Photography
        Association (NANPA).{' '}
      </p>
      <p>
        When she is not in some far-flung location Sue lives with her husband
        Chris Graham in North Wales, UK.
      </p>
    </article>
  </section>
)

export default AboutBio
