import React from 'react'

import awards2019 from '../../images/about/awards-2019.jpg'

const AboutAwards = () => (
  <section>
    <h2>Awards</h2>
    <figure>
      <img src={awards2019} alt="" />
      <figcaption>
        Sue’s image 'Big Blue Berg' was the winner of the Earth Photo 2019
        'Place' category
      </figcaption>
    </figure>

    <article>
      <p>
        In 2019 Sue was the overall winner of the 'Place' category in Earth
        Photo 2019, with her image from Antarctica 'Big Blue Berg'.
      </p>
      <p>
        Sue's many accolades include multiple awards in the International
        Photographer of the Year, Travel Photographer of the Year, and
        International Garden Photographer of the Year competitions; the Art
        Wolfe (best in festival) Prize in the International Conservation
        Photography Awards and a Royal Photographic Society silver medal. She is
        also a member of the Explorers Club, a Fellow of the Royal Geographical
        Society and was among a select band invited to meet HM The Queen at
        Buckingham Palace at an event celebrating Explorers and Adventurers.
      </p>
    </article>
  </section>
)

export default AboutAwards
