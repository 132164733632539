import React from 'react'
import Link from 'gatsby-link'

import photo from '../../images/shop/emperor.jpg'

const AboutBooks = () => (<div>
    <h2>Books</h2>
    <figure>
        <img src={photo} alt="Emperor: The Perfect Penguin" />
        <figcaption>Cover for Emperor: The Perfect Penguin</figcaption>
    </figure>
    <article>
        <p>
            Images and insights from Earth’s coldest places are collated in her two books – <strong>Cold Places</strong>, showcasing a rich variety of Polar people, wildlife and scenery, and the latest – <strong>Emperor: The Perfect Penguin</strong> – in praise of her favourite bird and its Antarctic home.
        </p>
        <p>
            For more details or to buy, <Link to="/shop/">please head to the shop</Link>.
        </p>
    </article>
</div>)

export default AboutBooks
