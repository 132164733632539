import React from 'react'
import Link from 'gatsby-link'

import photo from '../../images/gallery/papua-new-guinea/006091.jpg'

const AboutWorkshops = () => (
  <div>
    <h2>Workshops</h2>
    <figure>
      <img src={photo} alt="" />
      <figcaption>
        Sue Flood runs a wide range of workshops for photographers of all levels
      </figcaption>
    </figure>
    <article>
      <p>
        Sue Flood regularly leads beginners, improvers and advanced wildlife
        photography events, both for adults and young people, in the UK, abroad
        and at sea. To find out about upcoming opportunities, please see{' '}
        <Link to="/trips-events/">Trips & Events</Link>. To ask about hosting a
        workshop, please email{' '}
        <a href="mailto:info@sueflood.com">info@sueflood.com</a>.
      </p>
    </article>
  </div>
)

export default AboutWorkshops
