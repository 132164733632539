import React from 'react'
import Link from 'gatsby-link'

import photo from '../../images/index/008676.jpg'

const AboutGuided = () => (
  <section>
    <h2>Guided Trips</h2>
    <figure>
      <img src={photo} alt="" />
      <figcaption>
        Many people enjoy taking trips with Sue Flood, some of them, like
        Michael Palin, famous travellers in their own right!
      </figcaption>
    </figure>

    <article>
      <p>
        Sue leads photography tours to many different places, bespoke
        arrangements for private parties or small groups. To find out about
        upcoming opportunities, please see the{' '}
        <Link to="/trips-events/">Trips & Events</Link> or email{' '}
        <a href="mailto:info@sueflood.com">info@sueflood.com</a>.
      </p>
    </article>
  </section>
)

export default AboutGuided
