import React from 'react'

const AboutBooks = () => (
  <section>
    <h2>Talks</h2>
    <article>
      <p>
        Sue Flood is an accomplished and engaging public speaker, with
        experience of presenting to a wide variety of audiences including major
        international banks, businesses and law firms; high net worth charity
        donors; schools and universities, book festivals, adventure travel and
        photography shows, conservation gatherings and at events empowering
        women/girls. She always tailors her talks to suit her listeners and can
        vary the content/ style to entertain, enlighten, teach, inspire &/or
        motivate.
      </p>
      <p>
        To find out more, or book a talk, please email:{' '}
        <a href="mailto:info@sueflood.com">info@sueflood.com</a>
      </p>
    </article>
  </section>
)

export default AboutBooks
